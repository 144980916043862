import React from "react";
import Cookie from "js-cookie";
import HeaderLogo from "../../../Assets/logo_menu.png";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Lang } from "../../../i18n";
import "../View/less/MobileNavBar.css";

const MobileNavBar = (props) => {
    const [toggle, setToggle] = React.useState(false);

    const { t, i18n } = useTranslation();

    const toggleHandler = () => {
        setToggle(!toggle);
    };

    const changeLang = (newLang) => {
        i18n.changeLanguage(newLang);
        Cookie.set("lang", newLang, { expires: 6000 });
        Lang.lang = newLang;
    };

    const redirectTo = (link, noTryAgain) => {
        props.history.push(link);

        const elementID = link.substring(2);
        if (elementID === "tlap") window.scroll(0, 0);

        scrollToElement(elementID);
    };

    const scrollToElement = (elementID) => {
        const el = document.getElementById(elementID);
        if (el) {
            el.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest"
            });
        }
    };

    return (
        <div className="mobileMenu">
            <div className="mobileMenu__header">
                <div className="header-logo">
                    <img
                        onClick={() => props.history.push("/")}
                        src={HeaderLogo}
                        alt="heade_logo"
                    />
                </div>
                <div className="header__toggleButton" onClick={toggleHandler}>
                    <i className="flaticon-menu" />
                </div>
            </div>
            {toggle ? (
                <div className="mobile-menubar">
                    <div className="mobile-menubar__item">
                        <p>
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    setToggle(false);
                                    redirectTo("/#RÓLUNK");
                                }}
                                href="/#RÓLUNK"
                            >
                                {t("menuAboutUs")}
                            </a>
                        </p>
                    </div>

                    <div className="mobile-menubar__item">
                        <p>
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    setToggle(false);
                                    redirectTo("/#ASZTALFOGLALAS");
                                }}
                                href="/#ASZTALFOGLALAS"
                            >
                                {t("reservationTitle")}
                            </a>
                        </p>
                    </div>

                    <div className="mobile-menubar__item">
                        <a
                            href={"https://www.csulokvendeglo.hu/foods"}
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            <p>{t("menuMenu")}</p>
                        </a>
                    </div>
                    <div className="mobile-menubar__item mobile-menubar-order-button">
                        <a
                            href={"https://www.csulokvendeglo.hu/"}
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            <div className="menubar__order-button">{t("order")}</div>
                        </a>
                    </div>
                    {/* <div className="mobile-menubar__item" onClick={props.toggle}>
                        <Link>
                            <p>{t("surviriMenuText")}</p>
                        </Link>
                    </div> */}
                    <div className="mobile-menubar__langSel">
                        <p>
                            <span onClick={() => changeLang("hu")}>HU</span> /
                            <span onClick={() => changeLang("en")}>EN</span>
                        </p>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default withRouter(MobileNavBar);
