import React, { useEffect, useState } from "react";
import Logo from "../../../Assets/logo_menu.png";
import Cookie from "js-cookie";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Lang } from "../../../i18n";
import "./less/DesktopNavBar.css";
import "../../../Assets/icons/font/flaticon.css";

const DesktopNavBar = (props) => {
    const [scroll, setScroll] = useState(window.scrollY);
    const { t, i18n } = useTranslation();

    console.log(scroll);

    const changeLang = (newLang) => {
        i18n.changeLanguage(newLang);
        Cookie.set("lang", newLang, { expires: 6000 });
        Lang.lang = newLang;
    };

    const redirectTo = (link, noTryAgain) => {
        if (link !== props.location.pathname) props.history.push(link);

        const elementID = link.substring(2);

        scrollToElement(elementID);
    };

    const scrollToElement = (elementID) => {
        const el = document.getElementById(elementID);

        if (el) {
            el.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest"
            });
        }
    };

    useEffect(() => {
        document.addEventListener("scroll", () => {
            setScroll(window.scrollY);
        });
        return () => {
            document.removeEventListener("scroll");
        };
    }, []);

    return (
        <div className="desktopMenu">
            <div className="menubar">
                <div className="menubar__logo">
                    <img onClick={() => props.history.push("/")} src={Logo} alt="menu_logo" />
                </div>

                <div className="menubar__item">
                    <p>
                        <a
                            href="/#RÓLUNK"
                            onClick={(e) => {
                                e.preventDefault();
                                redirectTo("/#RÓLUNK");
                            }}
                        >
                            {t("menuAboutUs")}
                        </a>
                    </p>
                </div>

                <div className="menubar__item">
                    <p>
                        <a
                            href="/#ASZTALFOGLALAS"
                            onClick={(e) => {
                                e.preventDefault();
                                redirectTo("/#ASZTALFOGLALAS");
                            }}
                        >
                            {t("reservationTitle")}
                        </a>
                    </p>
                </div>

                <div className="menubar__item">
                    <a
                        href={"https://www.csulokvendeglo.hu/foods"}
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        <p>{t("menuMenu")}</p>
                    </a>
                </div>

                <div className="menubar__item">
                    <a
                        href={"https://www.csulokvendeglo.hu/"}
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        <div className="menubar__order-button">{t("order")}</div>
                    </a>
                </div>
                <div className="menubar__langSel">
                    <p>
                        <span onClick={() => changeLang("hu")}>HU</span> /
                        <span onClick={() => changeLang("en")}>EN</span>
                    </p>
                </div>

                <div className="menubar__toggleButton">
                    <i className="flaticon-menu" />
                </div>
            </div>
            {/*!scroll && (
                <div className="eu-logo">
                    <a href="/tajekoztato.pdf" target="_blank" rel="noreferrer noopener">
                        <img src={EU} alt="EU Támogatás" />
                    </a>
                </div>
            )*/}
        </div>
    );
};

export default withRouter(DesktopNavBar);
