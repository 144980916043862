import React from "react";
import About from "../../About/View/About";
//import WeeklyMenuController from "../../WeeklyMenu/Controller/WeeklyMenuController";
import Reservation from "../../Reservation/Controller/Reservation";
import Footer from "../../Footer/View/Footer";

const MainPage = () => {
    return (
        <div className="under">
            <About />
            {/*<WeeklyMenuController />*/}
            <Reservation />
            <Footer />
        </div>
    );
};

export default MainPage;
