import React from "react";
import AboutImg2 from "../../../Assets/beerMainPage.png";
import AboutImg1 from "../../../Assets/wurstMainPage.png";
import Image from "react-image";
import Image2 from "../../../Assets/baw_jasper2.jpg";
import Image3 from "../../../Assets/baw_jasper3.jpg";
//import mozaikImage01 from "../../../Assets/003_baw.jpg";
//import mozaikImage02 from "../../../Assets/007_baw.jpg";
//import mozaikImage03 from "../../../Assets/009_baw.jpg";
//import mozaikImage04 from "../../../Assets/010_baw.jpg";
//import mozaikImage05 from "../../../Assets/012_baw.jpg";
//import mozaikImage06 from "../../../Assets/013_baw.jpg";
//import mozaikImage07 from "../../../Assets/016_baw.jpg";
//import mozaikImage08 from "../../../Assets/018_baw.jpg";
//import mozaikImage09 from "../../../Assets/014_baw.jpg";
//import mozaikImage10 from "../../../Assets/022_baw.jpg";
import Img from "react-image";
import { useTranslation } from "react-i18next";
import "./less/About.css";

const About = (props) => {
    const { t } = useTranslation();

    /*const mozaikImages = [
        mozaikImage01,
        mozaikImage02,
        mozaikImage09,
        mozaikImage10,
        mozaikImage05,
        mozaikImage04,
        mozaikImage08,
        mozaikImage07,
        mozaikImage03,
        mozaikImage06
    ];*/

    return (
        <React.Fragment>
            <div className="about-container" id="RÓLUNK">
                <div className="container-fluid">
                    <div className="about-us">
                        <div className="about-us__image-container">
                            <div className="aboutImg">
                                <Img src={AboutImg2} alt="rolunk-2" />
                            </div>

                            <div className="aboutImg">
                                <Img src={AboutImg1} alt="rolunk-1" />
                            </div>
                        </div>
                        <h1 className="about-us__title">{t("aboutUsTitle")}</h1>
                        <div className="about-us__text">{t("aboutUsText")}</div>
                    </div>
                </div>
            </div>
            <div className="kitchen-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-md-6 col-12 order-2">
                            <Image src={Image2} className="kitchen-image" />
                        </div>
                        <div className="col-xl-4 col-md-6 col-12 order-3">
                            <Image src={Image3} className="kitchen-image" />
                        </div>
                        <div className="col-xl-4 col-md-12 col-12 order-1">
                            <div className="kitchen-text">
                                <div className="kitchen-title">{t("kitchenTitle")}</div>
                                {t("kitchenText")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="mozaik-container">
                {mozaikImages.map((imageURL, index) => (
                    <div
                        className="mozaik-image"
                        key={`${index}-about-img`}
                        style={{ backgroundImage: `url(${imageURL})` }}
                    />
                ))}
                </div>*/}
        </React.Fragment>
    );
};

export default About;
